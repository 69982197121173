import { BottomSheet, Illustration } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";

interface CreateSentHomeConfirmationBottomSheetProps {
  modalState: UseModalState;
  onContinue: () => void;
}

export function CreateSentHomeConfirmationBottomSheet(
  props: CreateSentHomeConfirmationBottomSheetProps
) {
  const { modalState, onContinue } = props;

  return (
    <BottomSheet
      modalState={modalState}
      canDismiss={false}
      footer={
        <DialogFooter orientation="vertical">
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={() => {
              modalState.closeModal();
              onContinue();
            }}
          >
            Continue
          </Button>
        </DialogFooter>
      }
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: 9,
        }}
      >
        <Stack alignItems="center" spacing={8} sx={{ px: 2 }}>
          <Illustration type="request-sent" />
          <Title variant="h2" component="h3" sx={{ textAlign: "center" }}>
            Your request has been sent
          </Title>
          <Text align="center">
            You&apos;ll receive an email when it&apos;s been reviewed by the workplace
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
