import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

export function BreakPaymentRequestsEmptyState() {
  return (
    <Stack
      sx={{
        flex: 1,
        flexGrow: 1,
        alignItems: "center",
      }}
      justifyContent="center"
      spacing={5}
    >
      <Title semibold variant="h4" component="h3" sx={{ textWrap: "balance", textAlign: "center" }}>
        You don&apos;t have any break payment requests
      </Title>
      <Text
        semibold
        textAlign="center"
        variant="body2"
        sx={{ paddingBottom: 8, textWrap: "balance" }}
      >
        Break payment requests will appear here when you work a shift without taking your mandatory
        break
      </Text>
    </Stack>
  );
}
