import { Browser } from "@capacitor/browser";
import { isCapacitorPlatform } from "@src/appV2/lib";

import { Button } from "../../components/Button";

const BREAK_PAYMENT_REQUEST_SUPPORT_ARTICLE_URL =
  "https://support.clipboardhealth.com/hc/en-us/articles/21529695509655-Automatic-Breaks-and-Worked-Break-Payment-Requests";

export function WorkedBreaksLearnMoreButton() {
  return (
    <Button
      fullWidth
      variant="outlined"
      onClick={async () => {
        if (isCapacitorPlatform()) {
          await Browser.open({
            url: BREAK_PAYMENT_REQUEST_SUPPORT_ARTICLE_URL,
          });
        } else {
          window.open(BREAK_PAYMENT_REQUEST_SUPPORT_ARTICLE_URL, "_blank");
        }
      }}
    >
      Learn more
    </Button>
  );
}
