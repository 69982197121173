import {
  Callout,
  CardWithIntent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@clipboard-health/ui-components";
import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";

interface CreateSentHomeRequestInfoProps {
  facilityName: string;
  shouldShowSentHomeRequestLineItems: boolean;
  isSentHomeRequestPayable: boolean;
  payDurationInHours: number;
  payableAmountInDollars: number;
}

export function CreateSentHomeRequestInfo(props: CreateSentHomeRequestInfoProps) {
  const {
    facilityName,
    shouldShowSentHomeRequestLineItems,
    isSentHomeRequestPayable,
    payDurationInHours,
    payableAmountInDollars,
  } = props;

  return (
    <Stack spacing={3} sx={{ paddingX: 4, paddingBottom: 8 }}>
      <Title semibold variant="h6" component="h6">
        Things you should know about your sent home request
      </Title>

      <List sx={{ "& .MuiListItem-root": { py: 3 } }}>
        {shouldShowSentHomeRequestLineItems &&
          (isSentHomeRequestPayable ? (
            <>
              <ListItem>
                <ListItemIcon iconType="dot" />
                <ListItemText>
                  <Text variant="body1">
                    {facilityName} offers{" "}
                    <Text bold variant="body1">
                      {payDurationInHours}
                    </Text>{" "}
                    hours of sent home pay
                  </Text>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon iconType="dot" />
                <ListItemText>
                  <Text>
                    You&apos;ll receive{" "}
                    <Text bold variant="body1">
                      {formatDollarsAsUsd(payableAmountInDollars)}
                    </Text>{" "}
                    once your request has been approved. The payment may take up to 1 week to
                    process.
                  </Text>
                </ListItemText>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem>
                <ListItemIcon iconType="dot" />
                <ListItemText>
                  <Text variant="body1">{facilityName} does not offer sent home pay</Text>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon iconType="dot" />
                <ListItemText>
                  <Text>
                    If this request is approved, you will not receive any compensation but you will
                    avoid Attendance Score penalties
                  </Text>
                </ListItemText>
              </ListItem>
            </>
          ))}
        <ListItem>
          <ListItemIcon iconType="dot" />
          <ListItemText>
            <Text variant="body1">A timesheet is not required for this request</Text>
          </ListItemText>
        </ListItem>
      </List>

      <CardWithIntent intent="warning">
        <Stack spacing={4} sx={{ paddingX: 6, paddingTop: 4, paddingBottom: 6 }}>
          <Callout
            iconType="triangle-exclamation"
            title=""
            sx={(theme) => ({
              backgroundColor: theme.palette.shift?.urgent.background,
              color: theme.palette.shift?.urgent.text,
              width: 48,
              paddingLeft: 5,
            })}
          />
          <Text sx={{ color: (theme) => theme.palette.text.secondary }}>
            Rejected requests may lower your Attendance Score. Ask the workplace to verify your
            request before leaving. For illness or safety incidents, contact Clipboard Health
            support instead.
          </Text>
        </Stack>
      </CardWithIntent>
    </Stack>
  );
}
