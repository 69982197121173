import { getTheme } from "@clipboard-health/ui-theme";
import { Alert, ThemeProvider } from "@mui/material";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { PageWrapper } from "@src/appV2/redesign/components/PageWrapper";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { useExtraWorkedTimeRequests } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useExtraWorkedTimeRequests";
import { useRef } from "react";

import { PageContent } from "../components/PageContent";
import { BreakPaymentRequestsEmptyState } from "./EmptyState";
import { BreakPaymentRequestList } from "./List";
import { BreakPaymentRequestsLoadingPageState } from "./LoadingPageState";

export function BreakPaymentRequestsPage() {
  const {
    data: extraWorkedTimeRequestsData,
    refetch: refetchExtraWorked,
    isLoading,
    isError,
    isSuccess,
  } = useExtraWorkedTimeRequests();

  const pendingBreakPaymentRequests = extraWorkedTimeRequestsData?.data ?? [];

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper>
        <StickyScrollPageHeader
          title="Break Payment Requests"
          isLoading={isLoading}
          scrollContainerRef={scrollRef}
        />
        <PageContent ref={scrollRef}>
          <PullToRefresh
            onRefresh={async () => {
              await refetchExtraWorked();
            }}
          />
          {isLoading && <BreakPaymentRequestsLoadingPageState />}
          {isError && <Alert severity="error">Error loading break payment requests.</Alert>}
          {isSuccess &&
            (pendingBreakPaymentRequests.length > 0 ? (
              <BreakPaymentRequestList extraWorkedTimeRequestsData={extraWorkedTimeRequestsData} />
            ) : (
              <BreakPaymentRequestsEmptyState />
            ))}
        </PageContent>
      </PageWrapper>
    </ThemeProvider>
  );
}
