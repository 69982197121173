import { Card, List, PillSkeleton, TextSkeleton } from "@clipboard-health/ui-components";
import { Stack } from "@mui/material";

export function CreateSentHomeRequestSkeleton() {
  return (
    <Stack
      spacing={3}
      sx={{ paddingX: 4, paddingBottom: 8 }}
      aria-label="Loading sent home request form"
    >
      <TextSkeleton variant="h6" width="60%" />

      <List sx={{ "& .MuiListItem-root": { py: 3 } }}>
        <Stack spacing={3}>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ py: 3 }}>
            <PillSkeleton width={8} height={8} />
            <TextSkeleton variant="body1" width="80%" />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ py: 3 }}>
            <PillSkeleton width={8} height={8} />
            <TextSkeleton variant="body1" width="70%" />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ py: 3 }}>
            <PillSkeleton width={8} height={8} />
            <TextSkeleton variant="body1" width="75%" />
          </Stack>
        </Stack>
      </List>
      <Card
        outlined
        variant="quaternary"
        sx={(theme) => ({
          borderColor: theme.palette.shift?.urgent.border,
          borderWidth: 1,
          borderRadius: (theme) => theme.borderRadius?.medium,
          backgroundColor: theme.palette.background.tertiary,
        })}
      >
        <Stack spacing={4} sx={{ paddingX: 6, paddingTop: 4, paddingBottom: 6 }}>
          <PillSkeleton width={48} height={48} />
          <TextSkeleton variant="body1" width="90%" />
          <TextSkeleton variant="body1" width="85%" />
        </Stack>
      </Card>

      {/* Form skeleton */}
      <Stack spacing={3} sx={{ paddingX: 4, paddingTop: 6 }}>
        <TextSkeleton variant="h6" width="40%" />
        <TextSkeleton variant="body1" width="90%" />
        <PillSkeleton height={120} width="100%" />
        <TextSkeleton variant="body2" width="95%" />
      </Stack>
    </Stack>
  );
}
