import { Divider, DotSeparatedList, Pill } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { type ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

import { WorkerShiftRequestCard } from "../components/RequestCard";
import { WorkedBreaksLearnMoreButton } from "./WorkedBreaksLearnMoreButton";

interface BreakPaymentRequestCardProps {
  shiftTimeSlot: ShiftPeriod;
  shiftStart: string;
  shiftEnd: string;
  shiftAgentReq: string;
  workplaceName: string;
  breakTimeInMinutes: number;
  breakPay: number;
  approvalDate: string;
}

export function BreakPaymentRequestCard(props: BreakPaymentRequestCardProps) {
  const {
    shiftTimeSlot,
    shiftStart,
    shiftEnd,
    shiftAgentReq,
    workplaceName,
    breakTimeInMinutes,
    breakPay,
    approvalDate,
  } = props;

  return (
    <WorkerShiftRequestCard
      shiftTimeSlot={shiftTimeSlot}
      shiftStart={shiftStart}
      shiftEnd={shiftEnd}
      pills={[
        <Pill
          key="pending-break-payment"
          label="Pending break payment"
          color={(theme) => theme.palette.intent?.neutral.text}
          backgroundColor={(theme) => theme.palette.intent?.neutral.background}
          iconColor={(theme) => theme.palette.intent?.neutral.icon}
        />,
      ]}
      details={
        <>
          <Text semibold color={(theme) => theme.palette.text.secondary} variant="body2">
            <DotSeparatedList>{[shiftAgentReq, workplaceName].filter(Boolean)}</DotSeparatedList>
          </Text>
          <Text semibold color={(theme) => theme.palette.text.secondary} variant="body2">
            <DotSeparatedList>
              {[formatDollarsAsUsd(breakPay), `${breakTimeInMinutes} mins skipped break time`]}
            </DotSeparatedList>
          </Text>
        </>
      }
    >
      <Divider />
      <Stack spacing={4}>
        <Text semibold variant="h6">
          Your request is pending
        </Text>
        <Text variant="body2">
          {workplaceName} will approve or decline your break payment request by {approvalDate}.
        </Text>
        <Box pt={4}>
          <WorkedBreaksLearnMoreButton />
        </Box>
      </Stack>
    </WorkerShiftRequestCard>
  );
}
