import { CbhIcon, TextArea } from "@clipboard-health/ui-components";
import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { type Control, Controller } from "react-hook-form";

import { type CreateSentHomeRequestFormValues } from "./types";

interface CreateSentHomeRequestFormProps {
  control: Control<CreateSentHomeRequestFormValues>;
}

export function CreateSentHomeRequestForm(props: CreateSentHomeRequestFormProps) {
  const { control } = props;

  return (
    <Stack
      spacing={4}
      sx={{
        backgroundColor: (theme) => theme.palette.background.primary,
        paddingX: 4,
        paddingY: 6,
      }}
    >
      <Title semibold variant="h6" component="h6">
        Share why you were sent home
      </Title>

      <Text variant="body2" sx={{ color: (theme) => theme.palette.text.tertiary }}>
        Please describe in detail why you were sent home, including the name of the workplace
        representative (required)
      </Text>

      <Controller
        name="description"
        control={control}
        render={({ field }) => <TextArea {...field} rows={6} placeholder="Type here" />}
      />

      <Text
        variant="body2"
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        })}
      >
        <CbhIcon type="info" size="small" sx={{ color: (theme) => theme.palette.icon?.primary }} />
        This message will be shared with the workplace
      </Text>
    </Stack>
  );
}
