import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type ExtraWorkedTimeRequestsResponse } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useExtraWorkedTimeRequests";
import { type ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { roundUpRateToNearestCentUsingCbhRounding } from "@src/appV2/Shifts/utils/payRateCalculation";
import { roundUpHoursToTwoDecimalsUsingCbhRounding } from "@src/appV2/Shifts/utils/timeCalculation";
import { addBusinessDays, differenceInMinutes, format, parseISO } from "date-fns";

import { BreakPaymentRequestCard } from "./Card";

interface BreakPaymentRequestListProps {
  extraWorkedTimeRequestsData: ExtraWorkedTimeRequestsResponse;
}

export function BreakPaymentRequestList({
  extraWorkedTimeRequestsData,
}: BreakPaymentRequestListProps) {
  const pendingBreakPaymentRequests = extraWorkedTimeRequestsData?.data ?? [];

  return (
    <Stack spacing={6}>
      {pendingBreakPaymentRequests.map((request) => {
        const shift = extraWorkedTimeRequestsData?.included.find(
          (included) => included.type === "shift" && included.id === request.attributes.shiftId
        );

        if (!isDefined(shift) || shift.type !== "shift") {
          return null;
        }

        const breakTimeInMinutes = differenceInMinutes(
          parseISO(request.attributes.end),
          parseISO(request.attributes.start)
        );
        const truncatedBreakTimeInHours = roundUpHoursToTwoDecimalsUsingCbhRounding(
          breakTimeInMinutes / 60
        );
        const breakPay = roundUpRateToNearestCentUsingCbhRounding(
          truncatedBreakTimeInHours * shift.attributes.pay
        );

        return (
          <BreakPaymentRequestCard
            key={request.id}
            shiftTimeSlot={shift.attributes.name as ShiftPeriod}
            shiftStart={shift.attributes.start}
            shiftEnd={shift.attributes.end}
            shiftAgentReq={shift.attributes.agentReq}
            workplaceName={shift.attributes.facility.name}
            breakTimeInMinutes={breakTimeInMinutes}
            breakPay={breakPay}
            approvalDate={format(
              addBusinessDays(parseISO(shift.attributes.end), 3),
              "MMM d, yyyy 'at' h:mm a"
            )}
          />
        );
      })}
    </Stack>
  );
}
